import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngMetaverseGamePage = loadable(() => import("../components/MetaverseGamePage/english.js"));
const ArabicMetaverseGamePage = loadable(() => import("../components/MetaverseGamePage/arabic.js"));
const ChineseMetaverseGamePage = loadable(() => import("../components/MetaverseGamePage/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section-23.webp",
            "name": "Leading Metaverse Game Development Company",
            "description": "Softtik is a top metaverse game development company with years of technical prowess and extensive experience in development services and solutions.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "141"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "What is metaverse game?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Metaverse is the next big revolution in the technological sector, which is re-shaping the gaming space. <b>Metaverse gaming</b> has transformed gaming from 2D to 3D in which players' interaction is 180 degrees different from traditional games.</p>"
                }
            }, {
                "@type": "Question",
                "name": "Is Metaverse just gaming?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>No, gaming is just a sector that the Metaverse is changing. <b>Metaverse</b> utilizes technologies like AR & VR to create an organic and unique experience for people.</p>"
                }
            }, {
                "@type": "Question",
                "name": "How is Metaverse different from gaming?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Players get to experience just as they would in the real world, thanks to Augmented Reality and Virtual Reality technology to offer a more organic encounter. Because the game platforms in the Metaverse are interoperable, players can move their virtual goods between spaces with little to no alteration.</p>"
                }
            }, {
                "@type": "Question",
                "name": "How do you create a Metaverse Game?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Game development is a difficult task that requires a certain amount of technical skills. You can share your idea with <b>Softtik Technologies</b>. Softtik has the best team with high skills to provide you with market-ready metaverse game development solutions.</p>"
                }
            }
            ]
        }
    ]
}

export class MetaverseGamePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English",
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        // else if (region == 'sa') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/metaverse-game-development-services/"
                        title="Metaverse Game Development Company - Softtik Technologies"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-23.webp"
                        description="Softtik is a top metaverse game development company with years of technical prowess and extensive experience in development services and solutions."
                    />
                    <main onWheel={this.onScroll}>
                        <div id="wrapper">
                            <div className={`collection-page game-development-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicMetaverseGamePage />
                                    : lang == "China"
                                        ? <ChineseMetaverseGamePage />
                                        : <EngMetaverseGamePage />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default MetaverseGamePage;